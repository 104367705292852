<template>
  <div :class="{ 'p-5': $route.name === 'registerSupplierWithoutAccount' }">
    <b-row>
      <b-col>
        <h1 class="font-weight-bolder">
          {{ $t('register', { type: $t('provider') }) }}
        </h1>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="overflow-hidden"
    >
      <validation-observer ref="supplierValidation">
        <b-row>
          <b-col>
            <b-card-body>
              <b-form @submit.prevent="validateForm()">
                <b-row>
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group :label="$t('companyName')">
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre de la empresa"
                        rules="required"
                      >
                        <b-form-input
                          v-model="supplier.name"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group label="Nombre del representante">
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre del representante"
                        rules="required"
                      >
                        <b-form-input
                          v-model="supplier.contact_name"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    lg="6"
                  >
                    <b-form-group label="Email del representante">
                      <validation-provider
                        #default="{ errors }"
                        name="Email del representante"
                        rules="required"
                      >
                        <b-form-input
                          v-model="supplier.email"
                          size="lg"
                          class="font-weight-bolder"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    lg="6"
                  >
                    <b-form-group :label="$t('phone')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('phone')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="supplier.phone"
                          size="lg"
                          class="font-weight-bolder"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group :label="$t('companyAddress')">
                      <gmap-autocomplete
                        class="form-control"
                        @place_changed="updateCenter($event)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    class="d-flex justify-content-end"
                  >
                    <b-button
                      variant="primary"
                      size="lg"
                      class="px-5"
                      type="submit"
                    >
                      {{ $t('save') }}
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$route.name === 'registerSupplierWithoutAccount'"
                    class="d-flex justify-content-end mt-1"
                  >
                    <span
                      class="text-primary cursor-pointer"
                      block
                      @click="$router.push({ name: 'auth-login' })"
                    >
                      {{ $t('alreadyHaveAnAccount') }}
                    </span>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-col>
          <b-col>
            <div id="root">
              <gmap-map
                ref="map"
                :center="center"
                :zoom="12"
                style="width: 100%; height: 750px"
              >
                <!-- TODO add pin -->
                <GmapMarker :position="marker" />
              </gmap-map>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Dropzone from '@/components/dropzone/Dropzone.vue'

export default {
  components: {
    // Dropzone,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      supplier: {
        name: '',
        contact_name: '',
        email: '',
        phone: '',
        address_attributes: {
          state: '',
          country: '',
          city: '',
          street: '',
          ext_number: '',
          postal_code: '',
          lat: '',
          lng: '',
          suburb: '',
        },
      },
      companyName: '',
      companyAddress: '',
      required,
      email,

      center: { lat: 25.6866142, lng: -100.3161126 },
      marker: {},
    }
  },
  computed: {
    ...mapGetters('suppliers', ['suppliers', 'newProviderFiles']),
    ...mapGetters('auth', ['currentUser']),
  },

  created() {},

  methods: {
    ...mapActions('suppliers', ['addSupplier', 'registerSupplier']),
    updateCenter(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.companyAddress = place

      const address = {
        name: '',
        state: '',
        country: '',
        city: '',
        street: '',
        ext_number: '',
        int_number: '',
        postal_code: '',
        lat: '',
        lng: '',
        suburb: '',
        formatted_address: '',
        url: '',
      }
      place.address_components.forEach(x => {
        if (x.types.includes('administrative_area_level_1')) {
          address.state = x.long_name
        }
        if (x.types.includes('country')) {
          address.country = x.long_name
        }
        if (x.types.includes('city') || x.types.includes('locality')) {
          address.city = x.long_name
        }
        if (x.types.includes('street') || x.types.includes('route')) {
          address.street = x.long_name
        }
        if (
          x.types.includes('ext_number')
          || x.types.includes('street_number')
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes('postal_code')) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes('suburb')
          || x.types.includes('sublocality_level_1')
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      this.supplier.address_attributes = address
    },
    validateForm() {
      this.$refs.supplierValidation.validate().then(success => {
        if (success) {
          if (this.currentUser.id) {
            this.addSupplier({
              ...this.supplier,
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('supplierCreated'),
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .then(() => {
                this.$router.push({ name: 'suppliers' })
              })
          } else {
            this.registerSupplier({
              ...this.supplier,
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('formSubmitted'),
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .then(() => {
                this.$router.push({ name: 'suppliers' })
              })
          }
        }
      })
    },
  },
}
</script>

<style></style>
