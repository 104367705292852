var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'p-5': _vm.$route.name === 'registerSupplierWithoutAccount' }},[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('register', { type: _vm.$t('provider') }))+" ")])])],1),_c('b-card',{staticClass:"overflow-hidden",attrs:{"no-body":""}},[_c('validation-observer',{ref:"supplierValidation"},[_c('b-row',[_c('b-col',[_c('b-card-body',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('companyName')}},[_c('validation-provider',{attrs:{"name":"Nombre de la empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.supplier.name),callback:function ($$v) {_vm.$set(_vm.supplier, "name", $$v)},expression:"supplier.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre del representante"}},[_c('validation-provider',{attrs:{"name":"Nombre del representante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.supplier.contact_name),callback:function ($$v) {_vm.$set(_vm.supplier, "contact_name", $$v)},expression:"supplier.contact_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Email del representante"}},[_c('validation-provider',{attrs:{"name":"Email del representante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg"},model:{value:(_vm.supplier.email),callback:function ($$v) {_vm.$set(_vm.supplier, "email", $$v)},expression:"supplier.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('phone')}},[_c('validation-provider',{attrs:{"name":_vm.$t('phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg"},model:{value:(_vm.supplier.phone),callback:function ($$v) {_vm.$set(_vm.supplier, "phone", $$v)},expression:"supplier.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('companyAddress')}},[_c('gmap-autocomplete',{staticClass:"form-control",on:{"place_changed":function($event){return _vm.updateCenter($event)}}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('b-button',{staticClass:"px-5",attrs:{"variant":"primary","size":"lg","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1),(_vm.$route.name === 'registerSupplierWithoutAccount')?_c('b-col',{staticClass:"d-flex justify-content-end mt-1"},[_c('span',{staticClass:"text-primary cursor-pointer",attrs:{"block":""},on:{"click":function($event){return _vm.$router.push({ name: 'auth-login' })}}},[_vm._v(" "+_vm._s(_vm.$t('alreadyHaveAnAccount'))+" ")])]):_vm._e()],1)],1)],1)],1),_c('b-col',[_c('div',{attrs:{"id":"root"}},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"750px"},attrs:{"center":_vm.center,"zoom":12}},[_c('GmapMarker',{attrs:{"position":_vm.marker}})],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }